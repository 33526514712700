<template>
    <div class="flex flex-col" v-if="show">
        <form
            @submit.prevent="$emit('fetch')"
            class="flex content-center items-center"
        >
            <span class="w-[250px] max-w-[250px] flex-none" v-if="dateFilter">
                <litepie-datepicker
                    v-model="filters.date"
                    :formatter="{
                        date: 'DD/MM/YYYY',
                        month: 'MMM',
                    }"
                    :shortcuts="false"
                />
            </span>
            <div
                v-if="searchFilter"
                class="grow"
                :class="{
                    'ml-2': dateFilter,
                    'flex w-full space-x-2': idFilter,
                }"
            >
                <input
                    v-if="idFilter"
                    v-model="filters.id"
                    type="text"
                    id="id"
                    class="block w-1/5 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    :placeholder="idFilterPlaceholder"
                />
                <input
                    v-model="filters.text"
                    type="text"
                    id="text"
                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    :class="{
                        'w-5/6': idFilter,
                    }"
                    :placeholder="searchLabel"
                    required
                />
            </div>
            <div
                class="ml-2 flex flex-none space-x-2"
                :class="{ 'ml-auto': !searchFilter }"
            >
                <button
                    @click.prevent="clearFilters"
                    :disabled="loading"
                    type="button"
                    :class="{ 'cursor-not-allowed': loading }"
                    class="btn-outline-primary px-4 py-1"
                >
                    Limpar filtros
                </button>

                <submit-button
                    :loading="loading"
                    @click.prevent="$emit('fetch')"
                    size="sm"
                >
                    Filtrar
                </submit-button>

                <slot name="actions" />
            </div>
        </form>
        <div
            class="my-3 grid grid-cols-4 gap-2"
            v-if="
                statusFilter ||
                paymentMethodFilter ||
                typeFilter ||
                categoryFilter ||
                modelsFilter ||
                languageFilter
            "
        >
            <multiple-select
                v-if="statusFilter"
                :options="statuses"
                v-model="filters.status"
                placeholder="Status"
            />

            <multiple-select
                v-if="paymentMethodFilter"
                :options="paymentMethods"
                v-model="filters.payment_method"
                placeholder="Método de Pagto."
            />

            <multiple-select
                v-if="typeFilter"
                :options="types"
                v-model="filters.types"
                placeholder="Tipo"
            />

            <multiple-select
                v-if="personTypeFilter"
                :options="personTypes"
                v-model="filters.person_types"
                placeholder="Tipo de Pessoa"
            />

            <multiple-select
                v-if="categoryFilter"
                :options="categories"
                v-model="filters.categories"
                placeholder="Categoria"
            />

            <div
                class="mb-3 flex items-center rounded border border-zinc-300 p-2 px-3 text-sm dark:bg-gray-900"
                v-if="creditCardQA"
            >
                <input
                    type="checkbox"
                    class="mr-3 h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                    v-model="filters.creditCardQA"
                    id="creditCardQA"
                />
                <label for="creditCardQA" class="cursor-pointer"
                    >Cartão de Crédito QA</label
                >
            </div>

            <multiple-select
                v-if="modelsFilter"
                :options="models"
                v-model="filters.models"
                placeholder="Modelo"
            />

            <multiple-select
                v-if="languageFilter"
                :options="languages"
                v-model="filters.languages"
                placeholder="Língua"
            />

            <slot></slot>
        </div>
        <div class="flex dark:text-white">
            <div
                v-if="
                    filters.status.length > 0 ||
                    filters.payment_method.length > 0 ||
                    filters.types.length > 0 ||
                    filters.categories.length > 0 ||
                    filters.creditCardQA ||
                    filters.models.length > 0 ||
                    filters.languages.length > 0 ||
                    filters.person_types.length > 0
                "
            >
                <span class="mb-1"> Filtrando por: </span>

                <div v-if="filters.person_types.length > 0" class="mb-2">
                    Tipo de Pessoa:
                    <span
                        v-for="personType in filteredPersonTypes"
                        @click="removePersonType(personType)"
                        class="mr-1 inline-block rounded bg-gray-500 px-2 py-1 text-xs font-semibold uppercase text-white last:mr-0 hover:cursor-pointer dark:bg-gray-900"
                    >
                        {{ personType.label }}
                        <font-awesome-icon
                            icon="fa-solid fa-times"
                            class="h-3 w-3 text-gray-200 dark:text-gray-200"
                            aria-hidden="true"
                        />
                    </span>
                </div>
                <div v-if="filters.status.length > 0" class="mb-3">
                    Status:
                    <span
                        v-for="status in filteredStatuses"
                        @click="removeStatus(status)"
                        class="mr-1 inline-block rounded bg-gray-500 px-2 py-1 text-xs font-semibold uppercase text-white last:mr-0 hover:cursor-pointer dark:bg-gray-900"
                    >
                        {{ status.label }}
                        <font-awesome-icon
                            icon="fa-solid fa-times"
                            class="h-3 w-3 text-gray-200 dark:text-gray-200"
                            aria-hidden="true"
                        />
                    </span>
                </div>

                <div v-if="filters.payment_method.length > 0" class="mb-3">
                    Método de Pagto.:

                    <span
                        v-for="paymentMethod in filteredPaymentMethods"
                        @click="removePaymentMethod(paymentMethod)"
                        class="mr-1 inline-block rounded bg-gray-500 px-2 py-1 text-xs font-semibold uppercase text-white last:mr-0 hover:cursor-pointer dark:bg-gray-900"
                    >
                        {{ paymentMethod.label }}
                        <font-awesome-icon
                            icon="fa-solid fa-times"
                            class="h-3 w-3 text-gray-200 dark:text-gray-200"
                            aria-hidden="true"
                        />
                    </span>
                </div>

                <div v-if="filters.types.length > 0" class="mb-3">
                    {{ typeLabel }}:
                    <span
                        v-for="type in filteredTypes"
                        @click="removeType(type)"
                        class="mr-1 inline-block rounded bg-gray-500 px-2 py-1 text-xs font-semibold uppercase text-white last:mr-0 hover:cursor-pointer dark:bg-gray-900"
                    >
                        {{ type.label }}
                        <font-awesome-icon
                            icon="fa-solid fa-times"
                            class="h-3 w-3 text-gray-200 dark:text-gray-200"
                            aria-hidden="true"
                        />
                    </span>
                </div>
                <div v-if="filters.categories.length > 0" class="mb-3">
                    Categoria:
                    <span
                        v-for="category in filteredCategories"
                        @click="removeCategory(category)"
                        class="mr-1 inline-block rounded bg-gray-500 px-2 py-1 text-xs font-semibold uppercase text-white last:mr-0 hover:cursor-pointer dark:bg-gray-900"
                    >
                        {{ category.label }}
                        <font-awesome-icon
                            icon="fa-solid fa-times"
                            class="h-3 w-3 text-gray-200 dark:text-gray-200"
                            aria-hidden="true"
                        />
                    </span>
                </div>
                <div v-if="filters.models.length > 0" class="mb-3">
                    Modelo:
                    <span
                        v-for="model in filteredModels"
                        @click="removeModel(model)"
                        class="mr-1 inline-block rounded bg-gray-500 px-2 py-1 text-xs font-semibold uppercase text-white last:mr-0 hover:cursor-pointer dark:bg-gray-900"
                    >
                        {{ model.label }}
                        <font-awesome-icon
                            icon="fa-solid fa-times"
                            class="h-3 w-3 text-gray-200 dark:text-gray-200"
                            aria-hidden="true"
                        />
                    </span>
                </div>
                <div v-if="filters.languages.length > 0" class="mb-3">
                    Língua:
                    <span
                        v-for="language in filteredLanguages"
                        @click="removeLanguage(language)"
                        class="mr-1 inline-block rounded bg-gray-500 px-2 py-1 text-xs font-semibold uppercase text-white last:mr-0 hover:cursor-pointer dark:bg-gray-900"
                    >
                        {{ language.label }}
                        <font-awesome-icon
                            icon="fa-solid fa-times"
                            class="h-3 w-3 text-gray-200 dark:text-gray-200"
                            aria-hidden="true"
                        />
                    </span>
                </div>
                <div v-if="filters.creditCardQA" class="mb-3">
                    <span
                        class="mr-1 inline-block rounded bg-gray-500 px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0 hover:cursor-pointer dark:bg-gray-900"
                    >
                        Filtrar pedidos com cartão de crédito QA
                        <font-awesome-icon
                            icon="fa-solid fa-times"
                            class="h-3 w-3 text-gray-200 dark:text-gray-200"
                            aria-hidden="true"
                            @click="filters.creditCardQA = false"
                        />
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from "vue";
import VueSelect from "../forms/VueSelect.vue";
import SubmitButton from "../forms/SubmitButton.vue";
import MultipleSelect from "../forms/MultipleSelect.vue";

export default {
    props: {
        loading: { type: Boolean, default: false },
        exportBtn: { type: Boolean, default: false },
        typeFilter: { type: Boolean },
        typeLabel: { type: String, default: "Tipo" },
        types: {
            type: Array,
            default: [
                { value: "checking", label: "Conta Corrente" },
                { value: "saving", label: "Conta Poupança" },
            ],
        },
        personTypeFilter: { type: Boolean, default: false },
        personTypes: { type: Array, default: () => [] },
        dateFilter: { type: Boolean },
        defaultDates: { type: Array, default: () => [] },
        statusFilter: { type: Boolean },
        defaultStatus: { type: Array, default: () => [] },
        statuses: {
            type: Array,
            default: [
                { label: "Abortado", value: "aborted" },
                { label: "Atrasado", value: "delayed" },
                { label: "Autorizado", value: "authorized" },
                { label: "Chargeback", value: "chargeback" },
                { label: "Em Disputa", value: "disputed" },
                { label: "Encerrado", value: "closed" },
                { label: "Expirado", value: "expired" },
                { label: "Incompleta", value: "error" },
                { label: "Pendente", value: "waiting_payment" },
                { label: "Período de Testes", value: "trial" },
                { label: "Processando", value: "processing" },
                { label: "Reclamado", value: "claimed" },
                { label: "Recusado", value: "refused" },
                { label: "Reembolsado", value: "refunded" },
                {
                    label: "Reembolsado Automaticamente",
                    value: "refunded_from_hood",
                },
                {
                    label: "Reembolso Cancelado pelo Produtor",
                    value: "refund_cancelled",
                },
                { label: "Reembolso Solicitado", value: "refund_requested" },
            ],
        },
        paymentMethodFilter: { type: Boolean },
        searchFilter: { type: Boolean },
        searchLabel: {
            type: String,
            default: "Pesquisar",
        },
        categoryFilter: { type: Boolean },
        categories: { type: Array },
        idFilter: { type: Boolean },
        idFilterPlaceholder: {
            type: String,
            default: "Id da transação",
        },
        creditCardQA: { type: Boolean, default: false },
        modelsFilter: { type: Boolean, default: false },
        models: { type: Array },
        languageFilter: { type: Boolean, default: false },
        languages: { type: Array },
    },
    components: { MultipleSelect, SubmitButton, VueSelect },

    data() {
        return {
            show: false,
            filters: {
                id: "",
                text: "",
                date: this.defaultDates,
                status: this.defaultStatus,
                payment_method: [],
                types: [],
                categories: [],
                creditCardQA: false,
                models: [],
                languages: [],
                person_types: [],
            },
            value: [],
            paymentMethods: [
                { value: "pix", label: "Pix" },
                { value: "credit_card", label: "Cartão de Crédito" },
                { value: "bank_slip", label: "Boleto" },
            ],
            person_types: [],
        };
    },

    computed: {
        filteredPersonTypes() {
            return this.filters.person_types.map((personType) => {
                return {
                    value: personType,
                    label: this.personTypes.find((s) => s.value === personType)
                        .label,
                };
            });
        },
        filteredPaymentMethods() {
            return this.filters.payment_method.map((paymentMethod) => {
                return {
                    value: paymentMethod,
                    label: this.paymentMethods.find(
                        (s) => s.value === paymentMethod,
                    ).label,
                };
            });
        },
        filteredStatuses() {
            return this.filters.status.map((status) => {
                return {
                    value: status,
                    label: this.statuses.find((s) => s.value === status).label,
                };
            });
        },
        filteredTypes() {
            return this.filters.types.map((type) => {
                return {
                    value: type,
                    label: this.types.find((s) => s.value === type).label,
                };
            });
        },
        filteredCategories() {
            return this.filters.categories.map((type) => {
                return {
                    value: type,
                    label: this.categories.find((s) => s.value === type).label,
                };
            });
        },
        filteredModels() {
            return this.filters.models.map((model) => {
                return {
                    value: model,
                    label: this.models.find((s) => s.value === model).label,
                };
            });
        },
        filteredLanguages() {
            return this.filters.languages.map((language) => {
                return {
                    value: language,
                    label: this.languages.find((s) => s.value === language)
                        .label,
                };
            });
        },
    },

    watch: {
        filters: {
            handler() {
                this.emit();
            },
            deep: true,
        },
    },

    async mounted() {
        await nextTick();

        setTimeout(() => {
            this.show = true;
            this.emit();
        }, 100);
    },
    methods: {
        emit() {
            const params = {};
            if (this.statusFilter) {
                params["filter[status]"] = this.filters.status.join();
            }
            if (this.paymentMethodFilter) {
                params["filter[paymentMethod]"] =
                    this.filters.payment_method.join();
            }
            if (this.dateFilter) {
                params["filter[betweenDates]"] = this.filters.date.join();
            }
            if (this.searchFilter) {
                params["filter[text]"] = this.filters.text;
            }
            if (this.idFilter) {
                params["filter[id]"] = this.filters.id;
            }
            if (this.typeFilter) {
                params["filter[type]"] = this.filters.types.join();
            }
            if (this.categoryFilter) {
                params["filter[category]"] = this.filters.categories.join();
            }
            if (this.modelsFilter) {
                params["filter[model]"] = this.filters.models.join();
            }
            if (this.languageFilter) {
                params["filter[language]"] = this.filters.languages.join();
            }

            if (this.creditCardQA) {
                params["filter[creditCardQA]"] = this.filters.creditCardQA;
            }

            if (this.personTypeFilter) {
                params["filter[personType]"] = this.filters.person_types.join();
            }

            this.$emit("update:modelValue", params);
        },
        removePaymentMethod(paymentMethod) {
            this.filters.payment_method = this.filters.payment_method.filter(
                (item) => {
                    return item !== paymentMethod.value;
                },
            );
        },
        removeType(type) {
            this.filters.types = this.filters.types.filter((item) => {
                return item !== type.value;
            });
        },
        removeStatus(status) {
            this.filters.status = this.filters.status.filter((item) => {
                return item !== status.value;
            });
        },
        removeCategory(category) {
            this.filters.categories = this.filters.categories.filter((item) => {
                return item !== category.value;
            });
        },
        removeModel(model) {
            this.filters.models = this.filters.models.filter((item) => {
                return item !== model.value;
            });
        },
        removeLanguage(language) {
            this.filters.languages = this.filters.languages.filter((item) => {
                return item !== language.value;
            });
        },
        getStatusName(status) {
            switch (status) {
                case "aborted":
                    return "Abortado";
                case "delayed":
                    return "Atrasado";
                case "authorized":
                    return "Autorizado";
                case "chargeback":
                    return "Chargeback";
                case "disputed":
                    return "Em Disputa";
                case "closed":
                    return "Encerrado";
                case "expired":
                    return "Expirado";
                case "error":
                    return "Incompleta";
                case "waiting_payment":
                    return "Pendente";
                case "trial":
                    return "Período de Testes";
                case "processing":
                    return "Processando";
                case "claimed":
                    return "Reclamado";
                case "refused":
                    return "Recusado";
                case "refunded":
                    return "Reembolsado";
                case "refund_requested":
                    return "Reembolso Solicitado";
                default:
                    return "Em Análise";
            }
        },
        clearFilters() {
            this.filters = {
                id: "",
                date: [],
                text: "",
                status: [],
                payment_method: [],
                types: [],
                categories: [],
                models: [],
                languages: [],
                person_types: [],
            };

            this.emit();
            this.$emit("fetch");
        },
        removePersonType(personType) {
            this.filters.person_types = this.filters.person_types.filter(
                (item) => {
                    return item !== personType.value;
                },
            );
        },
    },
};
</script>

<style></style>
