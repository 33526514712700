<template>
    <back-button :route="{ name: 'finnancial.chargebacks.users' }" />

    <filters
        v-model="filters"
        @fetch="
            chargebacks = [];
            fetch();
        "
        :loading="loading"
        search-filter
        status-filter
        :statuses="[
            { label: 'Solicitado', value: 'requested' },
            { label: 'Pendente', value: 'pending' },
            { label: 'Aprovada', value: 'approved' },
            { label: 'Rejeitada', value: 'rejected' },
        ]"
        search-label="Ex.: TPCMA..."
    >
        <template #actions>
            <submit-button
                @click.prevent="add = true"
                :loading="loading"
                size="sm"
            >
                Importar
            </submit-button>
        </template>
            <form-toggle
            v-model="filters.pending_action"
            :loading="loading"
                label="Mostrar Pendentes"
                @change="fetch"
            >
            </form-toggle>
    </filters>
    <div class="bg-blue-100 border-l-4 border-blue-500 p-4 mb-4" v-if="numberOfAlerts > 0">
        <div class="flex items-center justify-between">
            <div class="flex items-center">
                <div class="flex-shrink-0">
                    <span class="text-blue-700 font-medium">Atenção!</span>
                </div>
                <div class="ml-3">
                    <p class="text-sm text-blue-700">
                        Existem {{ numberOfAlerts }} notificações pendentes
                    </p>
                </div>
            </div>
            <submit-button
                @click="importNotifications"
                :loading="apiLoading"
            >
                Importar Notificações
            </submit-button>
        </div>
    </div>
    <ethoca-import
        :open="add"
        @close="add = false"
        @fetch="
            add = false;
            fetch();
        "
    />
    <chargeback-refund-modal
        :open="chargebackRefundModalOpen"
        :transaction="selectedTransaction"
        @close="chargebackRefundModalOpen = false"
        @closeModalAndFetch="
            chargebackRefundModalOpen = false;
            selectedTransaction = null;
            fetch();
        "
        @fetch="
            chargebackRefundModalOpen = false;
            fetch();
        "
    />
    <div class="sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full sm:px-6 lg:px-8">
            <div class="not-prose relative bg-slate-50 rounded-xl dark:bg-slate-800/25">
                <table-component
                    :loading="loading"
                    :headers="[
                        '',
                        'Alert At.',
                        'ID Externo',
                        'Autorização',
                        'Desc.',
                        'Valor',
                        'Transação',
                        'Produto',
                        'Status',
                    ]"
                    :pagination="pagination"
                    @fetch="fetch"
                >
                    <tr v-for="chargeback in chargebacks" :key="chargeback.id" :class="{ 'bg-yellow-100': chargeback.isPendingAction, 'bg-green-100': chargeback.isNew }">
                        <td class="table-cell-sm">
                            <div class="flex space-x-2">
                                #{{ chargeback.id }}
                            <dropdown>
                                <template #menu-items class="z-50">
                                    <MenuItem
                                        v-if="
                                            !chargeback.transaction ||
                                            (chargeback.is_pre_associated &&
                                                chargeback.transaction?.status !==
                                                    'refunded')
                                        "
                                        class="w-full"
                                        v-slot="{ close }"
                                    >
                                        <button
                                            class="block px-4 py-2 text-left text-sm text-gray-700 hover:font-bold"
                                            @click.prevent="
                                                close();
                                                selected = chargeback.id;
                                            "
                                        >
                                            {{
                                                chargeback.is_pre_associated
                                                    ? "Associar outra venda"
                                                    : "Associar"
                                            }}
                                        </button>
                                    </MenuItem>
                                    <MenuItem
                                        v-if="chargeback.transaction"
                                        class="w-full"
                                        v-slot="{ close }"
                                    >
                                        <button
                                            class="block px-4 py-2 text-left text-sm text-gray-700 hover:font-bold"
                                            @click.prevent="
                                                close();
                                                openOutcomeModal(chargeback);
                                            "
                                        >
                                            Outcome
                                        </button>
                                    </MenuItem>
                                    <MenuItem
                                        v-if="
                                            chargeback.transaction && !chargeback.confirmed
                                        "
                                        class="w-full"
                                        v-slot="{ close }"
                                    >
                                        <button
                                            class="block px-4 py-2 text-left text-sm text-gray-700 hover:font-bold"
                                            @click.prevent="
                                                close();
                                                confirmAssociation(chargeback);
                                            "
                                            :disabled="
                                                confirmingChargeback === chargeback.id
                                            "
                                        >
                                            Confirmar e Estornar
                                        </button>
                                    </MenuItem>
                                    <MenuItem
                                        v-if="chargeback.confirmed"
                                        class="w-full"
                                        v-slot="{ close }"
                                    >
                                        <span
                                            class="block px-4 py-2 text-left text-sm text-green-600"
                                        >
                                            Confirmado
                                        </span>
                                    </MenuItem>

                                    <MenuItem
                                        class="w-full"
                                        v-slot="{ close }"
                                        v-if="
                                            chargeback.transaction &&
                                            chargeback.transaction.status === 'authorized'
                                        "
                                    >
                                        <button
                                            class="block px-4 py-2 text-left text-sm text-gray-700 hover:font-bold"
                                            @click.prevent="
                                                close();
                                                selectedTransaction =
                                                    chargeback.transaction;
                                                chargebackRefundModalOpen = true;
                                            "
                                        >
                                            Estornar
                                        </button>
                                    </MenuItem>
                                </template>
                            </dropdown>
                            <create-pre-chargeback
                                :open="selected === chargeback.id"
                                :chargeback="chargeback"
                                @close="selected = false"
                                @fetch="
                                    selected = false;
                                    fetch();
                                "
                                />
                            </div>
                        </td>
                        <td class="table-cell-sm">
                            <span
                                v-tooltip="chargeback.alerted_at"
                                class="text-xs"
                            >
                                {{ chargeback.alerted_at }}
                            </span>
                        </td>
                        <td class="table-cell-sm">
                            <span
                                v-tooltip="chargeback.external_id"
                                class="max-w-32 truncate block font-semibold text-green-600"
                            >
                                {{ chargeback.external_id }}
                            </span>
                        </td>
                        <td class="table-cell-sm">
                            <span class="text-xs">
                                {{
                                    new Date(chargeback.authorized_at).toLocaleDateString(
                                        "pt-BR",
                                    )
                                }}
                            </span>
                        </td>
                        <td class="table-cell-sm hidden">
                            {{ chargeback.card }}
                            <card-brand-icon :brand="chargeback.data.source" />
                        </td>
                        <td class="table-cell-sm">
                            {{ chargeback.data.descriptor }}
                        </td>
                        <td class="table-cell-sm">
                            {{ $root.formatCurrency(chargeback.amount) }}
                        </td>
                        <td class="table-cell-sm">
                            <div class="flex items-center">
                                
                                <router-link
                                    v-if="chargeback.transaction"
                                    :to="{
                                        name: 'user.order',
                                        params: {
                                            order_id: chargeback.transaction.order_id,
                                        },
                                    }"
                                    class="mr-2 text-indigo-400 hover:cursor-pointer hover:text-indigo-700 hover:underline"
                                    target="_blank"
                                >
                                    #{{ chargeback.transaction.order_id }}
                                </router-link>
                                <span v-else class="mr-2">
                                    {{ chargeback.transaction_id ?? "--" }}
                                </span>
                                <span
                                    v-if="chargeback.is_pre_associated"
                                    class="text-xs font-semibold text-green-600"
                                    v-tooltip="'Pré-associado'"
                                >
                                    <LinkIcon class="h-4 w-4" />
                                </span>
                            </div>
                        </td>
                        <td class="table-cell-sm">
                            <span v-if="chargeback.transaction">
                                {{
                                    chargeback.transaction.order.items
                                        .map((item) => item.product.name)
                                        .join(", ")
                                }}
                            </span>
                        </td>
                        <td class="table-cell-sm">
                            <span v-if="chargeback.transaction">
                                <status :status="chargeback.transaction.status" size="sm" />
                            </span>
                        </td>
                    </tr>
                </table-component>
            </div>
        </div>
    </div>

    <outcome-confirmation-modal
        :open="outcomeModalOpen"
        :chargeback="selectedChargeback"
        @close="outcomeModalOpen = false"
        @outcome-confirmed="fetch"
    />
</template>

<script>
import ChargebackRefundModal from "../../../../views/orders/modals/ChargebackRefundModal.vue";
import BackButton from "../../../../components/layouts/navigation/BackButton.vue";
import PaginationObject from "../../../../mixins/PaginationObject.vue";
import TableComponent from "../../../../components/layouts/table/TableComponent.vue";
import Filters from "../../../../components/layouts/Filters.vue";
import ChargebackStatusName from "../../../../mixins/ChargebackStatusName.vue";
import SubmitButton from "../../../../components/forms/SubmitButton.vue";
import CreatePreChargeback from "../../../../components/chargeback/pre/CreatePreChargeback.vue";
import EthocaImport from "./EthocaImport.vue";
import CardBrandIcon from "../../../../components/layouts/CardBrandIcon.vue";
import Status from "../../../../components/layouts/Status.vue";
import Dropdown from "../../../../components/layouts/dropdown/Dropdown.vue";
import FormToggle from "../../../../components/forms/FormToggle.vue";
import { MenuItem } from "@headlessui/vue";
import { LinkIcon } from "@heroicons/vue/24/outline";
import { useToast } from "vue-toastification";
import OutcomeConfirmationModal from "../../../../components/chargeback/pre/OutcomeConfirmationModal.vue";

export default {
    components: {
        MenuItem,
        Dropdown,
        LinkIcon,
        Status,
        CardBrandIcon,
        EthocaImport,
        CreatePreChargeback,
        SubmitButton,
        Filters,
        TableComponent,
        BackButton,
        ChargebackRefundModal,
        FormToggle,
        OutcomeConfirmationModal,
    },
    mixins: [PaginationObject, ChargebackStatusName],

    data() {
        return {
            apiLoading: false,
            add: false,
            selected: false,
            chargebacks: [],
            numberOfAlerts: 0,
            filters: {
                text: "",
                status: [],
            },
            loading: false,
            confirmingChargeback: null,
            chargebackRefundModalOpen: false,
            selectedTransaction: null,
            outcomeModalOpen: false,
            selectedChargeback: null,
        };
    },

    setup() {
        const toast = useToast();
        return { toast };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;

            let filters = {
                ...this.filters,
                page: this.pagination.current_page,
            };

            if (this.filters.pending_action) {
                filters['filter[pending_action]'] = true;
            }
        
            axios.get('/api/pre-chargebacks/ethoca/notifications').then(({ data }) => {
                this.numberOfAlerts = data.numberOfAlerts;
            });
            return axios
                .get("/api/pre-chargebacks/", {
                    params: filters,
                })
                .then(({ data }) => {
                    this.chargebacks = data.data;
                    this.setPagination(data);
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        openChargebackRefundModal(transaction) {
            this.selectedTransaction = transaction;
            this.chargebackRefundModalOpen = true;
        },
        confirmAssociation(chargeback) {
            this.confirmingChargeback = chargeback.id;

            axios
                .post(
                    `/api/pre-chargebacks/${chargeback.id}/confirm-association`,
                    {
                        transaction_id: chargeback.transaction.id,
                    },
                )
                .then(() => {
                    this.toast.success('PreChargeback confirmado e estornado com sucesso!');
                    this.fetch();
                })
                .catch((error) => {
                    this.toast.error('Erro ao confirmar e estornar!');
                })
                .finally(() => {
                    this.confirmingChargeback = null;
                    this.fetch();
                });
        },
        importNotifications() {
            this.apiLoading = true;
        
            axios.post('/api/pre-chargebacks/ethoca/notifications').then(({ data }) => {
                this.fetch().then(()=> {
                    this.apiLoading = false;
                });
            });
        },
        openOutcomeModal(chargeback) {
            this.selectedChargeback = chargeback;
            this.outcomeModalOpen = true;
        },
    },
};
</script>