<template>
    <modal size="4xl" :open="open" @close="$emit('close')" title="Confirmar Outcome">
        <chargeback-info-grid :chargeback="chargeback" />
        <p class="dark:text-white">Você tem certeza que deseja marcar o outcome para este chargeback?</p>
        <div class="mt-4 flex justify-end space-x-2">
            <submit-button
                @click="confirmOutcome"
                :loading="loading"
            >
                Confirmar
            </submit-button>
            <button
                @click="$emit('close')"
                class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md"
            >
                Cancelar
            </button>
        </div>
    </modal>
</template>

<script>
import Modal from "../../layouts/Modal.vue";
import SubmitButton from "../../forms/SubmitButton.vue";
import ChargebackInfoGrid from "./ChargebackInfoGrid.vue";

export default {
    components: {
        Modal,
        SubmitButton,
        ChargebackInfoGrid,
    },
    props: {
        open: Boolean,
        chargeback: Object,
    },
    emits: ['close', 'outcome-confirmed'],
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        confirmOutcome() {
            this.loading = true;
            axios.post(`/api/pre-chargebacks/${this.chargeback.id}/outcome`)
                .then(() => {
                    this.$emit('outcome-confirmed');
                })
                .catch((error) => {
                    this.$root.toast.error('Erro ao marcar outcome: ' + error.response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('close');
                });
        },
    },
};
</script>