<template>
    <div class="mb-5 grid grid-cols-4">
        <info-col title="External Id.">
            <span
                v-tooltip="chargeback.external_id"
                class="block max-w-[100px] truncate"
            >
                {{ chargeback.external_id }}
            </span>
        </info-col>
        <info-col title="Cartão">
            <div class="flex items-center">
                <card-brand-icon :brand="chargeback.data.source" />
                {{ chargeback.card }}
            </div>
        </info-col>
        <info-col title="Aut Em:">
            <div class="flex items-center">
                {{ formatDate(chargeback.authorized_at) }}
            </div>
        </info-col>
        <info-col title="Valor:">
            <div class="flex items-center">
                {{ formatCurrency(chargeback.amount) }}
            </div>
        </info-col>
        <info-col v-if="chargeback.transaction_id" title="Transação">
            <router-link
                :to="{ name: 'user.order', params: { order_id: chargeback.transaction.order_id } }"
                target="_blank"
                class="text-blue-500 hover:text-blue-600"
            >
                {{ chargeback.transaction_id }}
            </router-link>
        </info-col>
    </div>
</template>

<script>
import InfoCol from "../../layouts/InfoCol.vue";
import CardBrandIcon from "../../layouts/CardBrandIcon.vue";

export default {
    components: {
        InfoCol,
        CardBrandIcon,
    },
    props: {
        chargeback: {
            type: Object,
            required: true,
        },
    },
    methods: {
        formatCurrency(value) {
            return this.$root.formatCurrency(value);
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString('pt-BR');
        },
    },
};
</script>